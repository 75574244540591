<template>
  <section class="tables">
    <div class="container-fluid">
      <div class="card-header">
        <h3>Documentos de la incidencia</h3>
        <div></div>
      </div>

      <b-card-group deck>
        <b-card class="col-sm-4 h-auto">
          {{ selectMode }}
          <b-card-text>
            <b-table
              hover
              :items="itemsAnt"
              @row-clicked="indexAnt"
              :select-mode="selectMode"
            ></b-table>
            <b-table
              hover
              :items="itemsTra"
              @row-clicked="indexTra"
              :select-mode="selectMode"
            ></b-table>
            <b-table
              hover
              :items="itemsTer"
              @row-clicked="indexTer"
              :select-mode="selectMode"
            ></b-table>
            <b-table
              hover
              selectable
              :items="itemsDoc"
              @row-clicked="indexDoc"
              :select-mode="selectMode"
              ref="selectableTable"
            ></b-table>
          </b-card-text>
          <b-card-text class="mt-5 table-bordered px-3 py-3">
            <b-form-group label="Tipo de documento a subir">
              <b-form-radio-group
                id="radio-group"
                v-model="selectedRadio"
                name="radio-group"
              >
                <b-form-radio value="ANT">Antes</b-form-radio>
                <b-form-radio value="TRA">Durante</b-form-radio>
                <b-form-radio value="TER">Despues</b-form-radio>
                <b-form-radio value="DOC">Documentos</b-form-radio>
              </b-form-radio-group>
            </b-form-group>

            <div class="file-upload-form">
              <input
                id="upload"
                type="file"
                @change="previewImage"
                accept="image/*, .msg, .pdf, .dwg, .mp4, .ppt, .pptx, .xls, .xlsx, .xml"
                multiple
              />
            </div>
          </b-card-text>
        </b-card>

        <b-card class="col-sm-8">
          <b-card-header>
            <div class="row">
              <div class="col-sm-8">
                <h6 class="">Vista Previa Documento</h6>
              </div>
              <div class="col-sm-4">
                <a class="fa fa-trash mr-3" href="#" @click="eliminarDocto"></a>

                <a class="fa fa-download mr-5" href="#" @click="descargar"></a>

                <a
                  class="fa fa-arrow-left mr-3"
                  href="#"
                  @click="pageAnt"
                  v-if="pageCount > 1"
                ></a>
                <a
                  class="fa fa-arrow-right"
                  href="#"
                  @click="pageSigte"
                  v-if="pageCount > 1"
                ></a>
              </div>
            </div>
          </b-card-header>
          <b-card-text>
            <p v-if="errorDoc === 'ERROR'">No se encontró el documento.</p>
            <p v-if="errorDoc === 'ERROREXT'">La extension no esta registrada.</p>

            <pdf
              v-if="extension === 'pdf'"
              ref="myPdfComponent"
              :src="dataObj"
              :page="currentPage"
              @num-pages="pageCount = $event"
              @page-loaded="currentPage = $event"
            ></pdf>

            <object
              v-if="extension === 'img'"
              class="pdfobject mx-3 my-3"
              :data="imageData"
              type="image/jpeg"
              width="95%"
            ></object>

            <video
              id="myVideo"
              v-show="extension === 'mp4'"
              width="95%"
              controls
            >
              <source src="" type="video/mp4" />
              Your browser does not support the video tag.
            </video>

            <pre v-if="extension === 'xml'" lang="xml">{{ xmlString }}</pre>
          </b-card-text>
        </b-card>
      </b-card-group>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Avatar from "vue-avatar";
import pdf from "vue-pdf";

import download from "downloadjs";
import Compress from "compress.js";
export default {
  mounted: function () {
    this.getDoctos();
  },
  components: {
    Avatar,
    pdf,
  },
  data() {
    return {
      //pdf: "",

      imageData: "",
      imageTmp: "",
      dataObj: "",
      xmlString: "sadasd asdasd asdasdasd",

      extension: "xxx",
      nameFile: "",
      errorDoc: "xxx",

      selectMode: "single",

      currentPage: 1,
      pageCount: 0,

      selectedRadio: "ANT",
      id: this.$route.params.id,
      userEmail: this.$store.getters.userEmail,
      itemsAnt: [],
      itemsTra: [],
      itemsTer: [],
      itemsDoc: [],
      index: 0,
      tipo: "",
    };
  },
  methods: {
    descargar() {
      if (this.extension === "pdf") {
        const pdf = this.dataObj;
        download(pdf, this.getNameFile(), "application/pdf");
      } else if (this.extension === "img") {
        const pdf = this.imageData;
        download(pdf, this.getNameFile(), "image/jpeg");
      }else if (this.extension === "xml") {
        const xml = this.xmlString;
        //download(pdf, this.getNameFile(), "image/jpeg");
        download(xml, this.getNameFile(), "text/plain");
      }


      
    },
    pageAnt() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
      }
    },
    pageSigte() {
      if (this.currentPage < this.pageCount) {
        this.currentPage += 1;
      }
    },
    getDoctos(tipo = 0) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$cookies.get("token");
      axios
        .get(process.env.VUE_APP_ROOT_API + "api/secure/documentos/" + this.id)
        .then(
          (response) => {
            this.itemsAnt = response.data.ant;
            this.itemsTra = response.data.tra;
            this.itemsTer = response.data.ter;
            this.itemsDoc = response.data.doc;
            if (tipo === 0) {
              this.index = 0;
              if (this.itemsAnt.length > 0) {
                this.tipo = "ANT";
                this.getPhoto();
              } else if (this.itemsTra.length > 0) {
                this.tipo = "TRA";
                this.getPhoto();
              } else if (this.itemsTer.length > 0) {
                this.tipo = "TER";
                this.getPhoto();
              } else if (this.itemsDoc.length > 0) {
                this.tipo = "DOC";
                this.getPhoto();
              } else {
                this.imageData = "data:image/jpeg;base64,";
              }
            } else {
              /*this.tipo = this.selectedRadio;
                        if(this.tipo === "ANT"){                        
                            this.index = this.itemsAnt.length-1;
                            this.getPhoto();
                        }else if(this.tipo === "TRA"){                        
                            this.index = this.itemsTra.length-1;
                            this.getPhoto();
                        }else if(this.tipo = "TER"){                        
                            this.index = this.itemsTer.length-1;
                            this.getPhoto();
                        }else if(this.tipo = "DOC"){                        
                            this.index = this.itemsDoc.length-1;
                            this.getPhoto();
                        }*/
            }
            //this.getPhoto();
          },
          (error) => {}
        );
    },
    previewImage: function (event) {
      this.dataObj = "data:image/jpeg;base64,";
      this.imageData = "data:image/jpeg;base64,";
      var input = event.target;
      // Ensure that you have a file before attempting to read it
      for (let i = 0; i < input.files.length; i++) {
        var reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.imageTmp = e.target.result;
          this.subirImage(input.files[i].name);
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[i]);
      }

      /*if (input.files && input.files[0]) {
                    // create a new FileReader to read this image and convert to base64 format
                    var reader = new FileReader();
                    // Define a callback function to run, when FileReader finishes its job
                    reader.onload = (e) => {
                        // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
                        // Read image as base64 and set to imageData
                        this.imageData = e.target.result;
                        this.subirImage(input.files[0].name);
                    }
                    // Start the reader job - read file as a data url (base64 format)
                    reader.readAsDataURL(input.files[0]);                    
                    
                }*/
    },
    subirImage(name) {
      var datos = {
        tipo: this.selectedRadio,
        nameFile: name,
        emailUser: this.userEmail,
        idIncidencia: this.id,
        avatar: this.imageTmp,
      };
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$cookies.get("token");
      axios
        .post(
          process.env.VUE_APP_ROOT_API + "api/secure/documentos/upload",
          datos
        )
        .then(
          (response) => {
            document.getElementById("upload").value = "";
            this.getDoctos(1);
          },
          (error) => {
            console.log(
              "Error: " + JSON.stringify(error.response.data.message)
            );
          }
        );
    },
    getPhoto() {
      var datos = {
        tipo: this.tipo,
        nameFile: this.getNameFile(),
        emailUser: this.userEmail,
        idIncidencia: this.id,
        avatar: "",
      };
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$cookies.get("token");
      axios
        .post(
          process.env.VUE_APP_ROOT_API + "api/secure/documentos/image",
          datos
        )
        .then(
          (response) => {
            console.log(response.data.ext);
            console.log(response.data.avatar.length);
            console.log(response.data.nameFile);

            this.errorDoc = "";
            this.extension = "";
            this.imageData = "";
            this.dataObj = "";
            this.xmlString = "";
            this.imageTmp = "";

            var imgs = "jpg-jpeg-png-bmp-jfif";

            console.log(imgs.indexOf(response.data.ext));

            if (response.data.ext === "pdf") {
              this.extension = "pdf";
              this.dataObj =
                "data:application/pdf;base64," + response.data.avatar;
            } else if (response.data.ext === "mp4") {
              this.extension = "mp4";
              var video = document.getElementById("myVideo");
              //video.src = "data:video/mpeg;base64,AAABuiEAAQALgBexAAABuwAMgBexBeH/wMAg4ODgAAA...";
              video.src = "data:video/mp4;base64," + response.data.avatar;
              video.load();
            } else if (imgs.indexOf(response.data.ext) >= 0) {
              this.extension = "img";
              this.imageData = "data:image/jpeg;base64," + response.data.avatar;
            } else if (response.data.ext === "xml") {
              console.log("kakakakakakakakak");

              this.extension = "xml";
              this.xmlString = atob(response.data.avatar);
            } else {
              this.extension = "xxx";
              this.errorDoc = "ERROREXT";
              this.dataObj = "";
              this.imageData = "";
              this.videomp4 = "";
            }
          },
          (error) => {
            this.extension = "xxx";
            this.errorDoc = "ERROR";
            this.dataObj = "";
            this.imageData = "";
            this.videomp4 = "";
            //console.log("Error: " + JSON.stringify( error.response.data.message) );
          }
        );
    },

    eliminarDocto() {
      var datos = {
        tipo: this.tipo,
        nameFile: this.getNameFile(),
        emailUser: this.userEmail,
        idIncidencia: this.id,
        avatar: "",
      };
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$cookies.get("token");
      axios
        .post(
          process.env.VUE_APP_ROOT_API + "api/secure/documentos/eliminar/",
          datos
        )
        .then(
          (response) => {
            //this.imageData = "data:image/jpeg;base64," + response.data.avatar;
            //this.$router.replace({ path: '/portal/' + this.tabla });
            this.getDoctos();
          },
          (error) => {
            //this.imageData = "data:image/jpeg;base64,";
            //console.log("Error: " + JSON.stringify( error.response.data.message) );
          }
        );
    },
    getNameFile() {
      var nameFile = "";
      if (this.tipo === "ANT") {
        nameFile = this.itemsAnt[this.index].ANTECEDENTES;
      } else if (this.tipo === "TRA") {
        nameFile = this.itemsTra[this.index].TRABAJOS;
      } else if (this.tipo === "TER") {
        nameFile = this.itemsTer[this.index].TERMINADO;
      } else if (this.tipo === "DOC") {
        nameFile = this.itemsDoc[this.index].DOCUMENTOS;
      }
      return nameFile;
    },
    indexAnt(item, index, event) {
      this.index = index;
      this.tipo = "ANT";
      this.getPhoto();
    },
    indexTra(item, index, event) {
      this.index = index;
      this.tipo = "TRA";
      this.getPhoto();
    },
    indexTer(item, index, event) {
      this.index = index;
      this.tipo = "TER";
      this.getPhoto();
    },
    indexDoc(item, index, event) {
      this.index = index;
      this.tipo = "DOC";
      this.getPhoto();
    },
  },
};
</script>

<style>
pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
section.tables {
  padding: 20px 0;
}
div.card-header {
  padding: 0.8rem 1rem;
  margin-bottom: 1rem;
}
input[type="file"]#upload {
  color: transparent;
}
</style>
